import type { Auth0Role, Company } from "@/services/api/types";

export const RoleDict: Record<string, string[]> = {
  admin: ["admin", "all_features", "basic"],
  all_features: ["all_features", "basic"],
  basic: ["basic"],
  sales_demo: ["sales_demo"],
};

export const sortAndSetRoleOptions = (roles: Auth0Role[]) => {
  const roleOrder = Object.keys(RoleDict);

  const rolesSorted = roles.sort((a, b) => {
    const indexA = roleOrder.indexOf(a.name);
    const indexB = roleOrder.indexOf(b.name);
    return indexA - indexB;
  });

  return rolesSorted.map((role) => ({
    id: role.name,
    label: role.name,
    description: role.description,
  }))
};

export const sortAndSetCompanyOptions = (companies: Company[]) => {
  companies.sort((a, b) => {
    if (a.company_name < b.company_name) return -1;
    if (a.company_name > b.company_name) return 1;
    return 0;
  });

  return companies.map((company) => ({
    id: company.id,
    label: company.company_name,
  }));
};

export const mapRoleNamesToIDs = (selectedRole: string, roles: Auth0Role[]) => {
  const roleList = RoleDict[selectedRole as keyof typeof RoleDict] || [];

  if (roleList.length) {
    return roleList.flatMap((roleName) => {
      const id = roles.find((r) => r.name === roleName)?.id;
      return id ? [id] : [];
    });
  }
  return roleList;
};

export const getUserPriorityRole = (roles: Auth0Role[] | undefined) => {
  if (!roles?.length) {
    return "basic";
  }
  // Extract the keys from RoleDict to determine the priority order
  const priorityOrder = Object.keys(RoleDict);

  // Find the first role in the roles array whose name matches the highest
  // level key in the priority order
  return priorityOrder.find((key) => roles.some((role) => role.name === key));
};
