import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import * as AdminSharedStyled from "../shared/styled";
import Card from "@/components/shared-ui/Card";
import { CardHeading } from "../AdminDashboard/styled";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useApi from "@/contexts/ApiContext";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import Input from "@/components/shared-ui/Input";
import { useEffect, useState } from "react";
import type { Auth0User } from "@/services/api/types";
import Select from "@/components/shared-ui/Select";
import Button from "@/components/shared-ui/Button";
import Modal from "@/components/shared-ui/Modal";
import { AxiosError } from "axios";
import {
  sortAndSetRoleOptions,
  getUserPriorityRole,
  mapRoleNamesToIDs,
  sortAndSetCompanyOptions,
} from "../shared/admin.utils";

const AdminUserEdit = () => {
  const api = useApi();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [userUpdate, setUserUpdate] = useState<Auth0User>();
  const [userRole, setUserRole] = useState<string>();
  const [userRoleList, setUserRoleList] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [rolesModalOpen, setRolesModalOpen] = useState(false);

  const { data: user, isSuccess } = useQuery(
    ["get-user", userId],
    () => api.admin.user.get(userId || ""),
    {
      cacheTime: 0,
    }
  );
  const { data: companies } = useQuery(["get-companies"], () =>
    api.admin.company.getAll()
  );
  const { data: roles } = useQuery(["get-roles"], () =>
    api.admin.roles.getAll()
  );

  const updateUser = useMutation({
    mutationFn: api.admin.user.update,
    onSettled: () => {
      setSubmitted(true);
    },
  });

  useEffect(() => {
    if (user && isSuccess) {
      const priorityRole = getUserPriorityRole(user.roles);
      setUserUpdate(user);
      setUserRole(priorityRole);
    }
  }, [user, isSuccess]);

  if (!user || !userUpdate || !companies || !roles || !userRole) {
    return <LoadingScreen isAdmin />;
  }

  const companyOptions = sortAndSetCompanyOptions(companies);
  const roleOptions = sortAndSetRoleOptions(roles);

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const roleList = mapRoleNamesToIDs(e.target.value, roles);
    setUserRole(e.target.value);
    setUserRoleList(roleList);
  };

  const onSubmit = () => {
    setModalOpen(true);
    return false;
  };

  const displayError = () => {
    if (
      updateUser.error instanceof AxiosError &&
      updateUser.error.response?.data ===
        "You may not assign this user to the admin role"
    ) {
      return (
        <>
          <p>User updated but could not assign roles to user.</p>{" "}
          <p>
            Ensure the user has a Seccl email address if trying to assign the
            admin role.
          </p>
        </>
      );
    }
    return "Sorry there was an issue updating the user.";
  };

  return (
    <Page
      title="User edit"
      isAdmin
      customBreadcrumb={{ path: "/admin/users", label: "Back to users" }}
    >
      <Card>
        <CardHeading>Edit user</CardHeading>
        <Styled.Form>
          <Input
            label="Name"
            id="name"
            name="name"
            type="text"
            value={userUpdate.name}
            onChange={(e) =>
              setUserUpdate({
                ...userUpdate,
                name: e.target.value,
              })
            }
            data-testid="userNameFieldEdit"
          />
          <Input
            label="Email"
            id="email"
            name="email"
            type="email"
            disabled
            value={userUpdate.email}
          />

          <Select
            id="company"
            name="company"
            label="Company"
            value={userUpdate.app_metadata.companyId}
            onChange={(e) =>
              setUserUpdate({
                ...userUpdate,
                app_metadata: {
                  ...userUpdate.app_metadata,
                  companyId: e.target.value,
                },
              })
            }
            options={companyOptions}
          />
          <Select
            id="role"
            name="role"
            label="Role"
            options={roleOptions}
            value={userRole}
            onChange={handleRoleChange}
            hint={
              <>
                Find out more details about each role{" "}
                <AdminSharedStyled.OpenModalText
                  onClick={() => setRolesModalOpen(true)}
                >
                  here
                </AdminSharedStyled.OpenModalText>
                .
              </>
            }
          />
          <Styled.ButtonWrapper>
            <Button
              type="button"
              label="Submit changes"
              disabled={modalOpen}
              data-testid="editUserSubmit"
              onClick={onSubmit}
            />
          </Styled.ButtonWrapper>
        </Styled.Form>
      </Card>
      <Modal
        modalActive={rolesModalOpen}
        toggleModal={() => setRolesModalOpen((prev) => !prev)}
        title="User Roles"
        cancelButton={{
          label: "Close",
          onClick: () => {
            setRolesModalOpen(false);
          },
        }}
      >
        <AdminSharedStyled.RolesModalContainer>
          {roleOptions.map((role) => {
            return (
              <p key={role.id}>
                <strong>{role.label}</strong> - {role.description}
              </p>
            );
          })}
        </AdminSharedStyled.RolesModalContainer>
      </Modal>
      <Modal
        modalActive={modalOpen && !submitted}
        toggleModal={() => setModalOpen((prev) => !prev)}
        title="Are you sure?"
        actionButton={{
          label: "Save updates",
          onClick: () => {
            updateUser.mutate({
              user: userUpdate,
              rolesToAdd: userRoleList,
            });
          },
          "data-testid": "confirmationModalButton",
        }}
        cancelButton={{ label: "Cancel", onClick: () => setModalOpen(false) }}
      >
        <p>This will change the user details.</p>
        Ensure you have checked that everything is correct before submitting
        (especially company).
      </Modal>
      <Modal
        modalActive={modalOpen && submitted}
        toggleModal={() => setModalOpen((prev) => !prev)}
        title={updateUser.isSuccess ? "Successfully updated" : "Error"}
        cancelButton={{
          label: "Close",
          onClick: () => {
            setModalOpen(false);
            setSubmitted(false);
            if (updateUser.isSuccess) {
              navigate("/admin/users");
            }
          },
          "data-testid": "completionModalButton",
        }}
        disableOutsideClick={updateUser.isSuccess}
      >
        {updateUser.isSuccess && "The user has been successfully updated."}
        {updateUser.isError && displayError()}
      </Modal>
    </Page>
  );
};

export default AdminUserEdit;
