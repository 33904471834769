import styled from "styled-components";

export const OpenModalText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const RolesModalContainer = styled.div`
  text-align: left;
`;
